import { StaticImage } from "gatsby-plugin-image";

const PlayIcon = ({ className }) => {
  return (
    <StaticImage
      src="../../images/icons/play-icon.svg"
      alt="play"
      placeholder="none"
      layout="fixed"
      loading="eager"
      className={className}
      objectFit="cover"
      aria-hidden={true}
    />
  );
};

export default PlayIcon;
