import { graphql } from 'gatsby';
import { block } from 'bem-cn';
import loadable from '@loadable/component';

import Layout from 'layouts/';
import Heading from 'components/Heading';
import Image from 'components/Image';
import Container from 'components/Container';
import Paragraph from 'components/Paragraph';
import ScrollLink from 'components/Link/_scroll';
import PlayIcon from 'components/Icons/play';

import {
	faqItem,
	insideLink,
	faqBlock,
	renderAst,
} from 'components/markDownComponents';

const renderComToolkit = {
	faq: faqBlock,
	'faq-item': faqItem,
	'inside-link': insideLink,
};

const renderCom = renderAst(renderComToolkit);

const Module = loadable(() => import('components/Module'));
const GetStartedBlock = loadable(() => import('components/GetStarted'));

import './style.scss';

const cn = block('bonds-template');

const BondsTemplate = ({ data }) => {
	const { htmlAst } = data.markdownRemark;
	const { title, seo, description, moduleList } =
		data.markdownRemark.frontmatter;

	return (
		<Layout seo={seo}>
			<Container className={cn('container', { first: true })}>
				<div className={cn('heading')}>
					<Heading color='general' weight='bold' align='center'>
						{title}
					</Heading>
					<Paragraph>{description}</Paragraph>
					{/* <div className={cn('demo')}>
						<PlayIcon />
						<ScrollLink className={cn('scroll-link')} id='bonds-video-section'>
							Demo video
						</ScrollLink>
					</div> */}
				</div>
				<ul>
					{moduleList.map((module, index) => (
						<li
							key={module.title}
							className={cn('module', { position: index % 2 !== 0 })}
						>
							<Module data={module} isImagePositionRight={index % 2 === 0} />
						</li>
					))}
				</ul>
				{/* <div className={cn('video', { wrap: true })}>
					<Heading
						level={2}
						align='center'
						weight='bold'
						id='bonds-video-section'
					>
						Watch how it works
					</Heading>
					<div className={cn('video', { content: true })}>
						<Image src='video' alt='video' />
					</div>
				</div> */}
			</Container>
			<section className={cn('get-started', { wrap: true })}>
				<GetStartedBlock />
			</section>
			<Container className={cn('container', { faq: true })}>
				{renderCom(htmlAst)}
			</Container>
		</Layout>
	);
};

export default BondsTemplate;

export const pageQuery = graphql`
	query ($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			frontmatter {
				title
				seo {
					description
					image
					link
					title
				}
				description
				moduleList {
					description
					list
					subtitle
					title
					image
					alt
					additional
				}
			}
			htmlAst
		}
	}
`;
